import {
  ExternalLinkIcon,
  LinkIcon,
  UploadIcon,
  DownloadIcon,
  ShareIcon,
  PhotographIcon,
  DesktopComputerIcon as OutlinedDesktopComputerIcon,
  PhoneIcon as OutlinedPhonecon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  MailIcon,
  TrashIcon,
  PhoneIcon,
  PlusIcon,
  QrcodeIcon,
  DesktopComputerIcon,
  AdjustmentsIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import React from "react";
import AddPhotoIcon from "../../common/Icons/AddPhotoIcon/AddPhotoIcon";

export interface IconButtonProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alignment?: string;
  size: string;
  onClickFunc?: any;
  width?: string;
  height?: string;
  spacing?: string;
  type?: "button" | "reset" | "submit";
  icon?: any;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  color?: string;
}

export default function IconButton({
  size,
  onClickFunc,
  icon,
  id,
  color,
  customStyle,
}: IconButtonProps) {
  let style = "bg-white border border-gray-300 hover:bg-gray-50";
  let iconStyle;

  switch (color) {
    case "gray":
      style = style + " text-gray-500";
      break;
    case "red":
      style = style + " text-red-500";
      break;
    case "blue":
      style = style + " text-blue-500";
      break;
    default:
      style = style + " text-gray-500";
  }

  switch (size) {
    case "xs":
      size = "p-1";
      iconStyle = "h-5 w-5";
      break;
    case "sm":
      size = "p-1.5";
      iconStyle = "h-5 w-5";
      break;
    case "md":
      size = "p-2";
      iconStyle = "h-5 w-5";
      break;
    case "lg":
      size = "p-2";
      iconStyle = "h-6 w-6";
      break;
    case "xl":
      size = "p-3";
      iconStyle = "h-6 w-6";
      break;
  }

  if (icon) {
    switch (icon) {
      case "share":
        icon = <ShareIcon className={iconStyle} />;
        break;
      case "chat":
        icon = <ChatIcon className={iconStyle} />;
        break;
      case "mail":
        icon = <MailIcon className={iconStyle} />;
        break;
      case "link":
        icon = <LinkIcon className={iconStyle} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={iconStyle} />;
        break;
      case "plus":
        icon = <PlusIcon className={iconStyle} />;
        break;
      case "delete":
        icon = <TrashIcon className={iconStyle} />;
        break;
      case "upload":
        icon = <UploadIcon className={iconStyle} />;
        break;
      case "export":
        icon = <DownloadIcon className={iconStyle} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={iconStyle} />;
        break;
      case "phone":
        icon = <PhoneIcon className={iconStyle} />;
        break;
      case "outlined-phone":
        icon = <OutlinedPhonecon className={iconStyle} />;
        break;
      case "pencil":
        icon = <PencilIcon className={iconStyle} />;
        break;
      case "photograph":
        icon = <PhotographIcon className={iconStyle} />;
        break;
      case "add-photo":
        icon = <AddPhotoIcon className={iconStyle} />;
        break;
      case "desktop":
        icon = <DesktopComputerIcon className={iconStyle} />;
        break;
      case "outlined-desktop":
        icon = <OutlinedDesktopComputerIcon className={iconStyle} />;
        break;
      case "adjustments":
        icon = <AdjustmentsIcon className={iconStyle} />;
        break;
      case "qr":
        icon = <QrcodeIcon className={iconStyle} />;
        break;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        type="button"
        data-cy={id}
        className={`${size} ${style} inline-flex justify-center items-center rounded-full shadow-sm focus:outline-none`}
        onClick={onClickFunc}
      >
        {icon}
      </button>
    </>
  );
}
